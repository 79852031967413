/* Container for the shows section */
.shows-section-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .shows-section-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .shows-section-heading {
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #bbbbbb;
    font-size: 1.2rem;
  }
  
  .shows-section-main-title {
    font-family: 'Archivo Black', sans-serif;
    font-size: 5rem;
    text-transform: uppercase;
    margin: 0;
    color: #ffffff;
  }
  
  .shows-section-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .shows-section-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #5d5c5c;
  }
  
  .shows-section-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #000;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-right: 20px;
  }
  
  .shows-section-day {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .shows-section-month {
    font-size: 1.2rem;
  }
  
  .shows-section-info {
    flex-grow: 1;
  }
  
  .shows-section-title {
    font-family: 'Oswald', sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 0 10px;
    color: #f5f5f5;
  }
  
  .shows-section-location {
    font-size: 1rem;
    color: #cccccc;
  }
  
  .shows-section-ticket-button-container {
    margin-left: 20px;
  }
  
  .shows-section-ticket-button {
    background: none;
    border: 2px solid #fff;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .shows-section-ticket-button:hover {
    background-color: #fff;
    color: #121212;
  }
  
  .shows-section-loading {
    text-align: center;
    font-size: 1.2rem;
    color: #e0e0e0;
  }
  
  /* Mobile-Friendly Adjustments */
  @media screen and (max-width: 768px) {
    .shows-section-main-title {
      font-size: 3rem; /* Smaller heading for mobile */
    }
  
    .shows-section-item {
      flex-direction: column; /* Stack items vertically on mobile */
      align-items: flex-start; /* Align items to the left */
      padding: 15px;
    }
  
    .shows-section-date {
      margin-right: 0;
      margin-bottom: 10px; /* Add space between date and event info */
      width: 100%; /* Make the date box full-width on mobile */
      text-align: center;
    }
  
    .shows-section-info {
      width: 100%; /* Make the event info full-width */
      margin-bottom: 10px; /* Add space below the event info */
    }
  
    .shows-section-ticket-button-container {
      width: 100%; /* Make the button full-width */
      text-align: center;
      margin-left: 0;
    }
  
    .shows-section-ticket-button {
      padding: 10px 0; /* Adjust padding for mobile */
      width: 100%; /* Full-width button */
    }
  
    .shows-section-title {
      font-size: 1.2rem; /* Slightly smaller for mobile */
    }
  
    .shows-section-location {
      font-size: 0.9rem; /* Slightly smaller for mobile */
    }
  }
  
  /* Extra small screens */
  @media screen and (max-width: 480px) {
    .shows-section-header {
      margin-bottom: 20px;
    }
  
    .shows-section-main-title {
      font-size: 2.5rem; /* Even smaller for very small screens */
    }
  
    .shows-section-item {
      padding: 10px;
    }
  
    .shows-section-title {
      font-size: 1.1rem; /* Further reduced text size for small screens */
    }
  
    .shows-section-date {
      padding: 10px;
    }
  
    .shows-section-ticket-button {
      padding: 8px;
    }
  }