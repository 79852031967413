@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap);
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; /* For Firefox */
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    padding: 10px 20px;
    color: white;
    font-family: 'Bebas Neue', sans-serif; /* Apply Bebas Neue font */
}

.navbar-left .logo-image {
    height: 40px; /* Adjust height as needed */
    width: auto;
    margin-bottom: 0px;
}

.navbar-center {
    display: flex;
    align-items: center;
}

.navbar-center a {
    color: white;
    margin: 0 15px;
    text-decoration: none;
    font-size: 18px;
    text-transform: uppercase;
    font-family: 'Bebas Neue', sans-serif; /* Apply Bebas Neue font */
}

.navbar-center a:hover {
    color: gray;
}

.navbar-right {
    display: flex;
    align-items: center;
}

.navbar-right a {
    color: white;
    margin: 0 5px;
    font-size: 20px;
    text-decoration: none;
}

.navbar-right a:hover {
    color: gray;
}

/* MOBILE NAVBAR STYLES */
@media screen and (max-width: 768px) {
    .navbar-left,
    .navbar-center,
    .navbar-right {
        display: none; /* Hide the default navbar sections on mobile */
    }

    .navbar-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .navbar-mobile .logo-image {
        height: 30px; /* Adjust height for mobile */
        width: auto;
    }

    .navbar-mobile .menu-icon {
        font-size: 24px;
        cursor: pointer;
        color: white;
    }

    .navbar-menu {
        display: none; /* Hidden by default */
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        background-color: black;
        width: 100%;
        padding: 20px;
        position: absolute;
        top: 50px; /* Adjust to position right below the navbar */
        left: 0;
        z-index: 10;
    }

    .navbar-menu.open {
        display: flex; /* Display flex when menu is open */
    }

    .navbar-menu a {
        color: white;
        margin: 10px 0;
        font-size: 18px;
        text-decoration: none;
        font-family: 'Bebas Neue', sans-serif;
        text-transform: uppercase;
    }

    .navbar-menu a:hover {
        color: gray;
    }

    .navbar-menu .social-icons {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .navbar-menu .social-icons a {
        color: white;
        margin: 0 10px;
        font-size: 24px;
    }

    .navbar-menu .social-icons a:hover {
        color: gray;
    }
}

/* Ensure the mobile navbar is hidden on desktop */
.navbar-mobile,
.navbar-menu {
    display: none;
}

/* MOBILE NAVBAR STYLES */
@media screen and (max-width: 768px) {
    .navbar-mobile {
        display: flex; /* Show mobile navbar on small screens */
    }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  z-index: auto;
  
}

body {
/* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
background:white;

}


.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.music {
  /* background-image: url('./images/img-2.jpg'); */
  background-color: white;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  /* background-image: url('./images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  /* background-image: url('./images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.hero-wrap {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.hero-container {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;  /* Anchors content towards the bottom */
    align-items: flex-start;    /* Aligns content to the left */
    height: 100%;
    width: 100%;
    color: white;
    padding: 20px 50px 60px;  /* Adjusted padding for balanced appearance */
    margin: 0;
}

.full-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: gray;
}

.text-content {
    position: relative;
    max-width: 500px;
    margin-bottom: 30px;  /* Increased margin to prevent sticking to the bottom */
}

.hero-container h1 {
    font-family: 'Oswald', sans-serif;
    font-size: 36px;  /* Standard size, responsive adjustments below */
    line-height: normal;
    font-weight: 700;
    letter-spacing: -.025em;
    text-transform: uppercase;
    margin: 10px 0;
}

/* Responsive font sizes */

@media (min-width: 1200px) {
    .hero-container h1 {
        font-size: 64px;  /* Extra large screens */
    }
}

.hero-container .out-now-container {
    display: flex;
    align-items: center;
    font-family: 'Oswald', sans-serif;
    margin-bottom: 10px;
}

.hero-container .subheading {
    font-size: 20px;
    text-transform: uppercase;
    margin: 0 5px;
}

.hero-container .out-now-line {
    flex-grow: 1;
    height: 1px;
    background-color: white;
    margin: 0 10px;
}

.cta-buttons {
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    margin-top: 20px;
}

.cta-buttons .btns {
    font-family: 'Oswald', sans-serif;
    font-size: 18px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    background: none;
    position: relative;
    padding: 0 15px;
}

.cta-buttons .btns::after {
    content: '|';
    color: white;
    display: inline-block;
    margin-left: 15px;
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
}

.cta-buttons .btns:last-child::after {
    display: none;
}

.cta-buttons .btns:hover {
    color: gray;
}


@media (min-width: 768px) {
    .hero-container h1 {
        font-size: 48px;  /* Medium screens */
    }
}

@media (min-width: 992px) {
    .hero-container h1 {
        font-size: 56px;  /* Large screens */
    }
}

@media screen and (max-width: 960px) {
  .hero-container .out-now-line {
      width: 100%;
      height: 1px;
      background-color: white;
      margin: 0 10px;
  }

  .hero-container .subheading {
    font-size: 14px;  /* Increased font size from too small, adjust as necessary */
    text-transform: uppercase;
    margin: 0 5px;
    white-space: normal;  /* Allow normal text flow if nowrap was too restrictive */
    overflow: visible;  /* Make overflow visible for troubleshooting */
  }

  .hero-container{
    background: url(/static/media/ks-intro.1c156176.gif) center center/cover no-repeat;
    
  }

  .text-content {
      width: 90%;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .hero-container h1 {
      font-size: 56px;
      text-align: center;
  }
}



@media screen and (max-width: 768px) {
  .hero-container .out-now-line {
      width: 100%;
      height: 1px;
      background-color: white;
      margin: 0 10px;
  }

  .hero-container{
    background: url(/static/media/ks-intro.1c156176.gif) center center/cover no-repeat;
    
  }

  .hero-container .subheading {
    font-size: 14px;  /* Consistent size for better legibility */
    text-transform: uppercase;
    margin: 0 5px;
    white-space: normal;
    overflow: visible;
  }

  .text-content {
      width: 90%;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .hero-container h1 {
      font-size: 48px;  /* Adjusted for better fit on smaller screens */
      text-align: center;
  }
}


.footer-container {
  background-color: #000;
  color: #fff;
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  width: 100%; /* Ensures the logo container spans the full width */
}

.logo-image {
  height: 100px; /* Adjust the size as needed */
  width: auto;
}

.footer-links {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
}

.footer-links a:hover {
  color: #ccc;
}

.footer-social {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 30px;
}

.footer-social a {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

.footer-social a:hover {
  color: #ccc;
}

.footer-bottom {
  text-align: center;
  margin-bottom: 20px;
}

.footer-bottom-links {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.footer-bottom-links a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

.footer-bottom-links a:hover {
  color: #ccc;
}

.footer-copyright {
  font-size: 12px;
  color: #777;
}

.event-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Oswald', sans-serif;
  text-align: center;
  padding: 20px;
}

.event-image img {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 20px;
}

.event-heading{
    color: #f5f5f5;
    font-family: 'Archivo Black', sans-serif;
    font-size: 160px;
    font-weight: bold;
    opacity: 0.3;
}

.event-details {
  margin-left: 20px;
  max-width: 600px;
  text-align: center;
}

.event-details h1 {
  font-size: 36px;
  color: #d8d8d8;
  text-align: center; 
}

.event-details p {
  color: #a7a7a7;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.event-item {
  display: flex;
  flex-direction: row; /* Change to row to align date and event title horizontally */
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.event-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px; /* Space between date and event title */
}

.event-date .day {
  font-size: 36px;
  color: #fff;
  font-weight: bold;
}

.event-date .month {
  font-size: 18px;
  color: #ccc;
  text-transform: uppercase;
}

.event-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.event-info h3 {
  font-size: 24px;
  color: #919191;
  margin: 0;
}

.ticket-button {
  background: none;
  border: 1px solid #676767;
  border-radius: 20px;
  padding: 5px 15px;
  color: #868686;
  cursor: pointer;
  margin-left: 20px;
}

.ticket-button:hover {
  background-color: #333;
  color: #fff;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loading::before {
  content: '';
  border: 4px solid #f3f3f3;
  border-top: 4px solid #34db45;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (max-width: 960px) {
  .event-heading {
    font-size: 5rem;
  }

  .event-heading p{
    text-align: center;
  }

  .event-item {
    flex-direction: row;
    text-align: left;
  }

  .event-info {
    align-items: flex-start;
    text-align: left;
  }

  .event-date .day {
    font-size: 2.5rem; /* Bigger font for larger screens */
  }

  .event-date .month {
    font-size: 1.25rem; /* Bigger font for larger screens */
  }
}

@media (min-width: 600px) {
  .event-list {
    flex-direction: row;
    text-align: left;
  }

  .event-details {
    margin-left: 40px;
    text-align: left;
  }

  .event-details h1 {
    text-align: left;
  }

  .event-heading p{
    text-align: center;
  }

  .event-item {
    flex-direction: row;
    justify-content: space-between;
  }

  .event-info h3 {
    font-size: 2rem; /* Larger font for desktop */
  }

  .event-date .day {
    font-size: 3rem; /* Bigger date font */
  }

  .ticket-button {
    padding: 12px 30px; /* Increase padding for larger screens */
    font-size: 1.1rem;
  }
}
/* Tilt and Zoom Effect */
.artist-card {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transform: perspective(1000px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.artist-card:hover {
  transform: scale(1.05) rotateY(10deg) rotateX(10deg);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.artist-image {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.artist-card:hover .artist-image {
  transform: scale(1.1);
}

.artist-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.6));
  z-index: 1;
  pointer-events: none;
}

/* Artists Section */
body {
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.artists-section {
  position: relative;
  background-color: #121212;
  padding: 40px 0 0;
  text-align: center;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.artists-heading {
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  color: #f7f7f7;
  font-family: 'Archivo Black', sans-serif;
  font-size: 160px;
  font-weight: bold;
  opacity: 0.5;
  z-index: 0;
  white-space: nowrap; /* Prevents the heading from breaking into multiple lines */
}

.artists-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0;
  gap: 0;
  margin-top: 100px;
}

.artist-card {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transform: perspective(1000px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.artist-card:hover {
  transform: scale(1.05) rotateY(10deg) rotateX(10deg);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.artist-image {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.artist-card:hover .artist-image {
  transform: scale(1.1);
}

.artist-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.6));
  z-index: 1;
  pointer-events: none;
}

.artist-name {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff;
  font-size: 1.5em;
  font-weight: bolder;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 2;
  font-family: 'Oswald', sans-serif;
}

@media screen and (max-width: 1024px) {
  .artists-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .artists-section {
    padding: 5px 0 0;
  }

  .artists-heading {
    font-size: 100px;
    top: 20px;
  }

  .artists-grid {
    grid-template-columns: 1fr;
  }

  .artist-name {
    font-size: 1.2em;
  }
}
/* General styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #121212;
  color: #fff;
  margin: 0;
  padding: 0;
}

.video-page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px 20px; /* Added top padding to prevent overlap */
}

/* Main video styles */
.main-video {
  position: relative;
  margin-bottom: 20px;
}

.main-video-thumbnail {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.main-video-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  border-radius: 8px;
}

.main-video-overlay {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
}

.main-video-title {
  font-size: 36px;
  font-weight: 700;
}

.main-video-play-icon {
  font-size: 64px;
  color: #fff;
  margin-top: 10px;
}

/* Video section styles */
.video-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}

.video-container {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s, box-shadow 0.3s;
}

.video-container:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.5);
}

.video-thumbnail {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.video-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  border-radius: 8px;
}

.video-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 5px;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Loading message styles */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

/* End message styles */
.end-message {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #b3b3b3;
}

/* Media queries */
@media screen and (max-width: 768px) {
  .video-page-container {
    padding: 70px 20px 20px; /* Increase top padding for mobile */
  }

  .main-video-title {
    font-size: 28px;
  }

  .main-video-play-icon {
    font-size: 48px;
  }

  .video-title {
    font-size: 16px;
  }
}
body {
  font-family: 'Roboto', sans-serif;
  background-color: #121212;
  color: #fff;
  margin: 0;
  padding: 0;
}

.artist-detail {
  max-width: 1200px;
  margin: 0 auto;
}

.artist-hero {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.artist-hero-content {
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.artist-name-container {
  margin-bottom: 10px;
}

.artist-name {
  font-size: 48px;
  font-weight: 700;
  margin: 0;
}

.verified-artist {
  font-size: 16px;
  color: #1db954;
}

.monthly-listeners {
  font-size: 14px;
  color: #b3b3b3;
}

.artist-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px;
}

.artist-bio {
  display: flex;
  flex-direction: column;
}

.artist-bio h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.artist-bio p {
  font-size: 16px;
  line-height: 1.6;
}

.artist-social {
  display: flex;
  margin-top: 10px;
}

.social-icon {
  font-size: 30px;
  color: #fff;
  margin-right: 15px;
  transition: color 0.3s;
}

.social-icon:hover {
  color: #1db954;
}

.artist-spotify {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.artist-spotify iframe {
  border-radius: 8px;
  width: 100%;
  height: 380px;
  border: none;
}

.loading-spinner {
  position: absolute;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  z-index: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .artist-content {
    grid-template-columns: 1fr;
  }
}
/* Container for the shows section */
.shows-section-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .shows-section-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .shows-section-heading {
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #bbbbbb;
    font-size: 1.2rem;
  }
  
  .shows-section-main-title {
    font-family: 'Archivo Black', sans-serif;
    font-size: 5rem;
    text-transform: uppercase;
    margin: 0;
    color: #ffffff;
  }
  
  .shows-section-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .shows-section-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #5d5c5c;
  }
  
  .shows-section-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #000;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-right: 20px;
  }
  
  .shows-section-day {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .shows-section-month {
    font-size: 1.2rem;
  }
  
  .shows-section-info {
    flex-grow: 1;
  }
  
  .shows-section-title {
    font-family: 'Oswald', sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 0 10px;
    color: #f5f5f5;
  }
  
  .shows-section-location {
    font-size: 1rem;
    color: #cccccc;
  }
  
  .shows-section-ticket-button-container {
    margin-left: 20px;
  }
  
  .shows-section-ticket-button {
    background: none;
    border: 2px solid #fff;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .shows-section-ticket-button:hover {
    background-color: #fff;
    color: #121212;
  }
  
  .shows-section-loading {
    text-align: center;
    font-size: 1.2rem;
    color: #e0e0e0;
  }
  
  /* Mobile-Friendly Adjustments */
  @media screen and (max-width: 768px) {
    .shows-section-main-title {
      font-size: 3rem; /* Smaller heading for mobile */
    }
  
    .shows-section-item {
      flex-direction: column; /* Stack items vertically on mobile */
      align-items: flex-start; /* Align items to the left */
      padding: 15px;
    }
  
    .shows-section-date {
      margin-right: 0;
      margin-bottom: 10px; /* Add space between date and event info */
      width: 100%; /* Make the date box full-width on mobile */
      text-align: center;
    }
  
    .shows-section-info {
      width: 100%; /* Make the event info full-width */
      margin-bottom: 10px; /* Add space below the event info */
    }
  
    .shows-section-ticket-button-container {
      width: 100%; /* Make the button full-width */
      text-align: center;
      margin-left: 0;
    }
  
    .shows-section-ticket-button {
      padding: 10px 0; /* Adjust padding for mobile */
      width: 100%; /* Full-width button */
    }
  
    .shows-section-title {
      font-size: 1.2rem; /* Slightly smaller for mobile */
    }
  
    .shows-section-location {
      font-size: 0.9rem; /* Slightly smaller for mobile */
    }
  }
  
  /* Extra small screens */
  @media screen and (max-width: 480px) {
    .shows-section-header {
      margin-bottom: 20px;
    }
  
    .shows-section-main-title {
      font-size: 2.5rem; /* Even smaller for very small screens */
    }
  
    .shows-section-item {
      padding: 10px;
    }
  
    .shows-section-title {
      font-size: 1.1rem; /* Further reduced text size for small screens */
    }
  
    .shows-section-date {
      padding: 10px;
    }
  
    .shows-section-ticket-button {
      padding: 8px;
    }
  }
.contact-page-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Header image styles */
  .contact-header {
    position: relative;
    width: 100%;
    height: 300px; /* Adjust the height as needed */
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Contact content styles */
  .contact-content {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
  }
  
  /* Booking info styles */
  .booking-info {
    flex: 1 1;
    min-width: 250px;
    padding: 20px;
    background-color: #1e1e1e;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  }
  
  .booking-info h2 {
    font-size: 40px;
    margin-bottom: 20px;
    border-bottom: 2px solid #1db954;
    padding-bottom: 10px;
  }
  
  .booking-info p {
    font-size: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .serious-inquiries {
    color: #c8c8c8;
    font-size: 18px;
    margin-top: 35px;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
  }
  
  .booking-info a {
    color: #1db954;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .booking-info a:hover {
    color: #1ed760;
  }
  
  .booking-info .icon {
    margin-right: 10px;
    color: #1db954;
  }
  
  /* Contact form styles */
  .contact-form {
    flex: 2 1;
    min-width: 250px;
    padding: 20px;
    background-color: #1e1e1e;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  }

  .contact-form h2 {
    font-size: 40px;
    text-transform: uppercase;
  }

  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: none;
    background-color: #333;
    color: #fff;
  }
  
  .contact-form button {
    padding: 10px 20px;
    background-color: #1db954;
    color: #fff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s;
  }
  
  .contact-form button:hover {
    background-color: #1ed760;
  }
  
  .required {
    color: red;
  }
  
  /* Success banner styles */
  .success-banner {
    position: fixed;
    top: -50px;
    left: 0;
    width: 100%;
    background-color: #1db954;
    color: white;
    text-align: center;
    padding: 10px;
    transition: top 0.3s;
  }
  
  .success-banner.show {
    top: 0;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .contact-content {
      flex-direction: column;
    }
  
    .booking-info,
    .contact-form {
      margin-right: 0;
    }
  }
